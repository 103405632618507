<template>
      <div class="text-center viewStyle p-5" :style="{'background':data.background}">
           <img :src="require(`@/assets/images/home-assets/${data.imagesrc}`)" alt=""/>
           <p style="color:white" class="mt-5">
               {{
                   data.discription
               }}
           </p>
      </div>
</template>
<script>
export default {
props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
}
</script>
<style scoped>
.viewStyle{
      border-radius: 20px;
    padding: 10px;
    min-height: 16rem;
}
</style>