<template>
  <div id="packageCard" class="text-center" @click="ViewPackageDetail">
    <imageLazy
      :imageStyle="{ 'border-radius': '20px', height: '40vh', width: '100%' }"
      :imageClass="'doctorImage'"
      :src="baseURL + data.ImagePath"
      placeHolderType="package"
    /> 
    <h4 class="fontMedium" :class="colorclass" style="">
      {{ data.Name }}
    </h4>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components: {
    imageLazy,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    colorclass: {
      type: String,
      default: "whitecolor",
    },
  },
  data() {
    return {
      baseURL: domain,
    };
  },
  methods: {
    ViewPackageDetail() {
      debugger;
      if (!this.data.MedicalPackageCategoryID)
        this.$router.push("OurPackages/" + this.data.ID);
      else this.$emit("OpenPackage", this.data);
    },
  },
};
</script>
<style>
.contain {
  width: 200px;
  height: 300px;
}
.mainbox {
  position: absolute;
  height: 200px;
  max-width: 80%;
}
.box {
  width: 190px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
  border-radius: 15px;
  /* for demo purpose  */
}
#packageCard .stack-top {
  z-index: 9;
  margin: 0px 5px 0px 5px;
  /* for demo purpose  */
}
</style>
