<template>
  <div id="knowledge-base-page">
    <img class="text-center w-full" src="@/assets/images/home.png" alt="" />

    <div id="tabs">
      <div style="width: 100%" v-if="false">
        <div class="tabs">
          <a
            v-on:click="activetab = 1"
            v-bind:class="[activetab === 1 ? 'active' : 'unactive']"
            class="ml-5 mr-5"
            >{{ $t("Doctor") }}</a
          >
          <a
            v-on:click="activetab = 2"
            v-bind:class="[activetab === 2 ? 'active' : 'unactive']"
            class="ml-5 mr-5"
          ></a>
        </div>
      </div>

      <div style="margin: auto">
        <div v-if="activetab === 1">
          <vx-card>
            <div class="vx-row">
              <p
                class="
                  vx-col
                  lg:w-1/3
                  text-center
                  w-full
                  fontMedium
                  fontSizeMedium
                  mt-5
                "
              >
                {{ $t("SearchDoctors") }}
              </p>

              <div class="vx-col lg:w-1/4 w-full mt-5">
                <v-select
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  label="Name"
                  v-model="search.CountryID"
                  :options="countries"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('Destination')"
                />
              </div>
              <div class="vx-col lg:w-1/4 w-full mt-5">
                <v-select
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  label="Name"
                  v-model="search.SpecialtyID"
                  :options="specialties"
                  :placeholder="$t('Specialty')"
                  :reduce="(ID) => ID.ID"
                />
              </div>
              <feather-icon
                icon="SearchIcon"
                @click="
                  $router
                    .push(
                      'DoctorSearch/' +
                        search.CountryID +
                        '/' +
                        search.SpecialtyID
                    )
                    .catch(() => {})
                "
                color="primary"
                class="cursor-pointer navbar-fuzzy-search mr-4 mt-5"
              ></feather-icon>
            </div>
          </vx-card>
        </div>
        <div v-if="activetab === 2">
          <vx-card>
            <div class="vx-row">
              <div class="vx-row vx-col lg:w-3/4 w-full">
                <div class="vx-col lg:w-1/4 w-full mt-5">
                  <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    class="w-full"
                    v-model="search.CountryID"
                    label="Name"
                    :options="countries"
                    placeholder="Destination"
                    :reduce="(ID) => ID.ID"
                  />
                </div>
                <div class="vx-col lg:w-1/4 w-full mt-5">
                  <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    class="w-full"
                    v-model="search.RegionID"
                    :options="regions"
                    :reduce="(ID) => ID.ID"
                    placeholder="Hospitals Group"
                  />
                </div>

                <div class="vx-col lg:w-1/4 w-full mt-5">
                  <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    class="w-full"
                    v-model="search.RegionID"
                    :options="regions"
                    placeholder="Hospital"
                    :reduce="(ID) => ID.ID"
                  />
                </div>
                <div class="vx-col lg:w-1/4 w-full mt-5">
                  <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    class="w-full"
                    label="Name"
                    v-model="search.SpecialtyID"
                    :options="specialties"
                    :reduce="(ID) => ID.ID"
                    placeholder="Specialty"
                  />
                </div>
              </div>
              <feather-icon
                icon="SearchIcon"
                @click="showFullSearch = true"
                class="
                  cursor-pointer
                  navbar-fuzzy-search
                  vx-col
                  lg:w-1/4
                  w-full
                  mt-5
                "
              ></feather-icon>
            </div>
          </vx-card>
        </div>
      </div>
    </div>

    <!-- <splide :options="primaryOptions">
      <splide-slide v-for="item in services" :key="item.id">
        <home-card :data="item"></home-card>
      </splide-slide>
    </splide> -->

    <div class="vx-row p-10">
      <home-card
        v-for="item in services"
        :key="item.id"
        :data="item"
        class="vx-col lg:w-1/3 mt-5"
      ></home-card>
    </div>
    <div>
      <hr />
    </div>

    <vs-row
      class="mt-10 mb-10"
      vs-align="flex-start"
      vs-type="flex"
      vs-justify="center"
      vs-w="12"
    >
      <vs-col
        v-for="item in medicalPackages"
        :key="item.id"
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="3"
        vs-sm="12"
        vs-xs="12"
        style="height: 350px"
      >
        <package-card :data="item" />
      </vs-col>
    </vs-row>

    <div>
      <hr />
    </div>

    <h1 class="text-center mt-10 header">
      {{ $t("advantageschoose") }}
      <span style="color: primary"> Doctor </span>
      <span style="color: primary"> 4 </span>
      <span style="color: primary"> Me </span>
    </h1>
    <div class="vx-row mt-10">
      <div v-for="item in steps" :key="item.id" class="vx-col lg:w-1/3 w-full">
        <step :data="item" />
      </div>
    </div>
  </div>
</template>

<script>
import homeCard from "../components/home/homeCard.vue";
import step from "../components/home/step.vue";
import packageCard from "../components/home/packageCard.vue";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleMedicalPackage from "@/store/medicalPackage/moduleMedicalPackage.js";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
// or
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
// or
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
export default {
  components: {
    Splide,
    SplideSlide,
    homeCard,
    step,
    packageCard,

  },
  data() {
    return {
      activetab: 1,
      primaryOptions: {
        type: "loop",
        perPage: 3,
        perMove: 1,
        gap: "1rem",
        pagination: false,
      },
      services: [
        {
          id: 1,
          imagesrc: "doctor.png",
          title: this.$i18n.t("Doctors"),
          coming: false,
          PageName: "DoctorSearch",
        },
        {
          id: 2,
          imagesrc: "appointment.png",
          title: this.$i18n.t("OnlineAppointment"),
          coming: false,
          PageName: "AppointmentSearch",
        },
        {
          id: 3,
          imagesrc: "surgery.png",
          title: this.$i18n.t("SurgeryAndTreatment"),
          coming: false,
          PageName: "SurgeryAndTreatment",
        },
      ],
      steps: [
        {
          id: 1,
          imagesrc: "clock.png",
          discription: this.$i18n.t("clockDescribtion"),
        },
        {
          id: 2,
          imagesrc: "dollar.png",
          discription: this.$i18n.t("dollarDescribtion"),
        },
        {
          id: 3,
          imagesrc: "surface.png",
          discription: this.$i18n.t("surfaceDescribtion"),
        },
      ],
      knowledgeBaseSearchQuery: "",
      search: {},
    };
  },
  methods: {},
  computed: {
    specialties() {
      return this.$store.state.SpecialtyList.specialties;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    medicalPackages() {
      return this.$store.state.MedicalPackageList.medicalPackages;
    },
  },

  created() {
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    if (!moduleMedicalPackage.isRegistered) {
      this.$store.registerModule("MedicalPackageList", moduleMedicalPackage);
      moduleMedicalPackage.isRegistered = true;
    }
    this.$store.dispatch("MedicalPackageList/SearchMedicalPackage", {
      ValidDate: new Date(),
    });

    this.$store.dispatch("CountryList/GetAllCountries");
    this.$store.dispatch("SpecialtyList/GetAllSpecialties");

    this.$store.commit("auth/SET_BEARER", localStorage.getItem("accessToken"));
  },
  beforeCreate() {
    if (this.$acl.check("doctor")) this.$router.push("/doctor/Home");
    else if (this.$acl.check("hospital")) this.$router.push("/hospital/Home");
  },
};
</script>

<style lang="scss">
.input-span-placeholder {
  color: #626262;
  font-size: 1em;
}


/* Import Google Font */
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans);

/* RESET */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Style the tabs */
.tabs {
  overflow: hidden;

  margin-bottom: -2px; // hide bottom border
  width: 300px;
  margin: auto;
}

.tabs ul {
  list-style-type: none;
  margin-left: 20px;
}

.tabs a {
  float: left;
  cursor: pointer;
  padding: 12px 24px;
  transition: background-color 0.2s;
  border: 1px solid #ccc;
  border-right: none;
  background-color: #f1f1f1;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
}
.tabs a:last-child {
  border-right: 1px solid #ccc;
}

/* Change background color of tabs on hover */
.tabs a:hover {
  background-color: #aaa;
  color: #fff;
}

/* Styling for active tab */
.tabs a.active {
  background-color: #fff;
  color: #484848;
  border-bottom: 2px solid #fff;
  cursor: default;
}
.tabs a.unactive {
  color: #484848;
}

/* Style the tab content */
.tabcontent {
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 3px 3px 6px #e1e1e1;
}
</style>
