<template>
  <div id="homecard2" @click="MoreDetail(data)">
    <div>
      <h5  class="CardButton fontMfontBookedium primarycolor"  :class="[data.selected==true?'selected':'CardButton']">
        {{ data.title }}
         {{ data.Name }}
      </h5>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    search: {
      type: Object,
      default: () => {},
    },
     services: {
      type: Array,
      default: () =>[],
    },
  },
  
  methods: {
    MoreDetail(item) {
        this.$emit("CardClick",item)
    },

  },
};
</script>


<style >
#homecard2 .vx-card .vx-card__collapsible-content .vx-card__body {
  padding: 0;
}

#homecard2 .CardButton {
  background: var(--unnamed-color-454a62) 0% 0% no-repeat padding-box;
  background: #454a62 0% 0% no-repeat padding-box;
  border-radius: 32px 32px 0px 0px;
  opacity: 1;
  color: white;
  padding: 1.3rem;
 
  height: 4.5rem;
  text-align: center;
  /* left: 50%;
  margin-right: -50%; */
  display: flex;
  align-items: center;
  justify-content: center
}
/* .CardButton:active   {
  background-color: #76A224!important;
} */
.selected{
   background-color: #76A224!important;
}
</style>